.header {
  &_container {
    width: 100%;
    height: 50px;
    display: flex;
    margin-bottom: 10px;
    margin-top: 8px;
    gap: 12px;
    position: relative;
  }

  &_button {
    height: fit-content;
    width: fit-content;
    width: -moz-fit-content;
    height: -moz-fit-content;
    display: flex;
    align-items: center;
  }

  &_icon {
    height: 25px;
    width: 25px;
    color: var(--header-color);
    cursor: pointer;
    fill: var(--header-color);
  }
  &_icon:hover {
    color: var(--button-color);
    fill: var(--button-color);
  }
  &_iconSvg {
    height: 30px;
    width: 30px;
    color: var(--header-color);
    cursor: pointer;
    fill: var(--header-color);
    margin-top: 8px;
  }
  &_iconSvg:hover {
    color: var(--button-color);
    fill: var(--button-color);
  }

  &_icon__disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
  }

  &_icon__disabled:hover {
    color: var(--header-color);
    fill: var(--header-color);
  }

  &_project_menu,
  &_group_menu,
  &_task_menu,
  &_backMenu {
    display: flex;
    align-items: center;
    padding-right: 15px;
  }
  &_project_menu {
    border-right: 1.5px solid var(--header-color);
  }

  &_task_menu {
    border-right: 1.5px solid var(--header-color);
  }
  &_backMenu {
    &_icon {
      position: absolute;
      right: 25px;
    }
  }
  &_group_menu {
    border-right: 1.5px solid var(--header-color);
  }

  &_listView {
    text-align: center;
    margin: auto 20px;
  }
}

.projectName {
  font-size: 16px;
  font-weight: 500;
  line-height: 50px;
  padding: 0 15px;
  width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
