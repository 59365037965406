.popover {
  box-shadow: 1px solid black;

  &Container {
    width: 205px;
    color: #333;
    border-radius: 4px;
    position: fixed;
    z-index: 1;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 6px;
    z-index: 100000;
  }
  &Menu {
    width: 205px;
    background-color: white;
    list-style: none;
    position: relative;
    border-radius: 6px;

    &Color {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: white;
      z-index: 2;
      border-radius: 6px;
      top: 0;
      left: 205px;
      overflow: hidden;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    &_item {
      box-sizing: border-box;
      height: 35px;
      width: 100%;
      line-height: 1.5rem;
      font-weight: 600;
      font-size: 14px;
      font-weight: 400;
      border: #333;
      margin: auto;
      line-height: 14px;
      display: block;
      padding: 10px 10px 5px 10px;
      cursor: pointer;
      &:first-child:hover {
        border-radius: 6px 6px 0 0;
      }
      &:last-child:hover {
        border-radius: 0 0 6px 6px;
      }
      &:hover {
        background-color: #e2f2fe;
      }
    }
  }
}
