.input {
  width: 95%;
  // padding: 2px;
  padding-left: 5px;
  margin-right: 10px;
  outline: none;
  border: none;
  background-color: inherit;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  &:focus {
    outline: solid 1px var(--button-color);
    border-radius: 5px;
  }
  &:disabled {
    opacity: 0.6;
  }
}
