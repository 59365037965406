.main-menu {
  background-color: #f4f8fb;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.main-menu-scheduling {
  background-color: #f4f8fb;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.wrapper {
  width: 70%;
  margin: auto;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu--wrapper {
  width: 100%;
  align-items: center;
  margin-top: 36px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}

.search-bar {
  width: 100%;
  min-width: 700px;
  background: white;
  display: flex;
  align-items: center;
  border-radius: 6px;
  margin-top: 80px;
  margin-bottom: 80px;
  border: 0.5px solid rgb(191, 191, 191);
  border-radius: 6px;
}

.search-bar:focus {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.search-bar input {
  width: 100%;
  background: transparent;
  flex: 1;
  border: none;
  outline: none;
  height: 36px;
  padding-left: 10px;
  margin-right: 10px;
  border-right: 0.5px solid rgb(191, 191, 191);
}

#search-input:focus {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.search-bar button {
  position: relative;
  width: 36px;
  right: 5px;
}

@media (min-width: 1280px) and (max-width: 1365px) {
  .search-bar {
    margin-top: 2%;
    margin-bottom: 2%;
  }
}

@media (min-width: 1366px) and (max-width: 1919px) {
  .search-bar {
    margin-top: 3%;
    margin-bottom: 3%;
  }
}

@media (min-width: 1920px) {
}
