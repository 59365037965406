.ganttTable {
  display: table;
  border-bottom: 2px solid rgb(76, 100, 157);
  border-top: 1px solid rgb(196, 196, 196);
  border-left: 1px solid rgb(196, 196, 196);
}

.ganttTable_Header {
  display: table-row;
  list-style: none;
  background-color: white;
}

.ganttTable_HeaderSeparator {
  border-left: 1px solid rgb(196, 196, 196);
  margin-left: -0.5px;
  height: 100px;
  cursor: col-resize;
  position: relative;
}
.ganttTable_HeaderSeparator:hover {
  border-left: 2px solid var(--header-color);
}

.ganttTable_HeaderResize {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  cursor: col-resize;
  user-select: none;
}

.ganttTable_HeaderResize:hover {
  border-right: 2px solid blue;
}

.ganttTable_HeaderItem {
  display: table-cell;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
}
