
.defaultClass{
    width: 36px;
    height: 36px;
    border-radius: 50px;
    object-fit: fill;
    padding: -10px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.avatar--container {
    display: flex;
    height: 60px;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.avatar--container p {
    margin: 0px 10px ;
    padding: 0;
    line-height: 60px;
    margin-left: 10px;
}