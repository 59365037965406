.main--windows {
  height: 100%;
  width: 100%;
  display: flex;
}

.header-upper {
  display: flex;
  align-items: center;
  flex-direction: row;
  /* flex-wrap: nowrap; */
  justify-content: space-between;
  align-content: center;
  margin-top: 15px;
  width: 100%;
}

.header-upper input {
  height: 36px;
  border-radius: 8px;
  outline: none;
  border: 1px black solid;
  margin-left: 4px;
}

.upload--class {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.button-class {
  height: 36px;
  width: 80px;
  background-color: #0199fa;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  font-weight: 400;
  /* padding: 0px; */
}

.button-disable {
  background-color: rgb(189, 189, 189) !important;
  cursor: not-allowed !important;
}

.button-disable:hover {
  background-color: rgb(189, 189, 189) !important;
  box-shadow: none !important;
}

.button-class:hover {
  background-color: #018de6;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  color: black;
  height: 36px;
  width: 120px;
  background-color: #0199fa;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subfix--class {
  display: flex;
  flex-direction: row;
  justify-content: right;
  flex-wrap: wrap;
  width: 100%;
  margin-right: 36px;
}

.subfix--class div:nth-child(1) {
  margin-bottom: 5px;
}

.subfix--class input {
  padding: 10px 10px;
  border: 1px solid rgb(191, 191, 191);
  border-radius: 4px;
}

.subfix--class input:hover {
  border: 1px solid rgb(134, 134, 134);
}

.subfix--class input:focus {
  border: 1px solid #0199fa;
}

.subfix--class label {
  font-size: 15px;
  font-weight: 600;
}

.file--name {
  margin-left: 36px;
  margin-right: 36px;
  text-align: left;
  width: calc(100% - 72px);
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-items: left;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 1px;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.file--name label {
  line-height: 36px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}
.outer-wrapper {
  width: calc(100% - 72px);
  margin-left: 36px;
  margin-right: 36px;
  position: relative;
}

.table-wrapper {
  width: 100%;
  max-height: 695px;
  min-height: 200px;
  overflow-y: scroll;
}

#contents-table {
  font-family: Arial, Helvetica, sans-serif;
  /* border-collapse: collapse; */
  width: 100%;
}

#contents-table td,
#contents-table th {
  /* border: 1px solid #ddd; */
  padding: 8px;
  line-height: 14px;
  text-align: left;
}

.table--header th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  align-items: center;
  background-color: #02457a;
  color: white;
  line-height: 14px;
  position: "sticky";
  top: 0px;
  outline: 0.7px solid black;
  border: 1.5px solid black;
}

#contents-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#contents-table tr:hover {
  background-color: #ddd;
}

#contents-table td:focus {
  outline-color: #0199fa;
  outline-width: thin;
  outline-style: solid;
  border: none;
}

.select-cell {
  border: 2px solid rgb(36, 65, 255);
}

.error-cell {
    outline-color: red;
    outline-width: thin;
    outline-style: solid;
    border: none;
}

.warning-cell {
    outline-color: rgb(250, 133, 0);
    outline-width: thin;
    outline-style: solid;
    border: none;
}


.icon-class {
  padding-right: 5px;
}

.clickAble {
  cursor: pointer;
}
