.checkbox {
  &_input {
    display: none;
    &:checked + .checkbox_label::after {
      visibility: visible;
      opacity: 1;
    }
    &:checked + .checkbox_label {
      color: var(--header-color);
      font-weight: 500;
    }
  }
  &_label {
    position: relative;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    margin-right: 10px;
    padding: 5px 5px 5px;
    flex-grow: 1;
    &:hover {
      width: 335px;
      border-radius: 6px;
      background-color: #c9eeff;
      // color: white;
      font-weight: 500;
    }
    &::after {
      content: '';
      height: 10px;
      width: 16px;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-80%) translateX(3px) rotate(-45deg);
      border-left: 3px solid var(--header-color);
      border-bottom: 3px solid var(--header-color);
      visibility: hidden;
      opacity: 0;
      transform: 0.25s linear;
      cursor: pointer;
    }
  }
}
