.modal {
  &_overlay {
    background-color: rgba(85, 85, 85, 0.5);
    position: fixed;
    top: 0px;
    width: 100vw;
    height: calc(100vh + 105px);
    padding: 0;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  &_container {
    background-color: white;
    width: 500px;
    height: 160px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  &_header {
    text-align: center;
    width: 100%;
    margin-top: '35px';
  }
  &_message {
    margin-bottom: 20px;
  }
  &_button {
    padding: 8px 20px;
    min-width: 100px;
    font-size: 16px;
    border-radius: 6px;
    background: #0199fa;
    color: white;
    position: absolute;
    right: 16px;
    bottom: 16px;
    &:hover {
      background: #018de6;
    }
  }
}
