.main-color {
  background-color: white;
  width: 400px;
  border-radius: 5px;
  display: flex;
  position: relative;
  padding: 10px;
  flex-direction: column;
}
.main-color table {
  margin-top: 10px !important;
}

.main-color table td {
  border: 1px solid white;
}

.main-color table td:hover {
  border: 2px solid white;
  outline: 2px solid black;
  cursor: pointer;
}
.table--color {
  background-color: aliceblue;
  outline: 5px solid rgb(235, 246, 255);
}
.color--container {
  height: 16px !important;
  width: 16px !important;
}

.color--selected {
  outline: 2px solid black;
  border: 2px solid white;
}
