@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-family: 'Noto Sans JP', sans-serif;
}
html {
  font-size: 16px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-family: 'Noto Sans JP', sans-serif;
}
html {
  font-size: 16px;
}

.scroll-bar-custom {
  @apply scrollbar-thin scrollbar-thumb-[#cccccc] scrollbar-track-[#ffffff] scrollbar-thumb-rounded-full scrollbar-track-rounded-full;
}
.ant-btn {
  font-weight: 600 !important;
  font-size: 14px !important;
  height: 36px !important;
  width: 96px !important;
}
.ant-btn-primary {
  background-color: #0199fa !important;
}
.ant-btn-primary:hover {
  background-color: #018de6 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a:link {
  text-decoration: none;
  color: inherit;
}
a:visited {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}
a:active {
  text-decoration: none;
  color: inherit;
}

.Scrollbar-hidden-x .scrollbar-track-x {
  display: none !important;
}
