.wrapper {
  transform: translateX(-10px) translateY(-100px);
}

.heading {
  font-size: 24px;
  font-weight: 500;
  padding: 15px;
}

.container {
  background-color: white;
  height: 505px;
  width: 400px;
  border-radius: 8px;
}

.title {
  padding-left: 25px;
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
}

.taskContainer {
  border: 1px solid gray;
  border-radius: 6px;
  height: 330px;
  width: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 25px;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 5px;
}
.task {
  padding: 5px;
  padding-left: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 330px;
  &:hover {
    font-weight: 500;
    width: 330px;
    border-radius: 6px;
    background-color: #dbecf4;
  }
}
.group {
  padding: 5px;
  padding-left: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 330px;
  &:hover {
    font-weight: 500;
    width: 330px;
    border-radius: 6px;
    background-color: #dbecf4;
  }
}

.active {
  font-weight: 500;
  width: 330px;
  border-radius: 6px;
  background-color: #dbecf4;
}

.button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
}
