.wrapper {
  width: 220px;
  height: 340px;
  background-color: white;
  color: #333;
  box-shadow: 1px solid #d4edff;
  border-radius: 3px;
  overflow: hidden;
  list-style: none;
  position: fixed;
  z-index: 10000000000;
}

.button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 220px;
  background-color: white;
}
