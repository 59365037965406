.wrapper {
  transform: translateX(-10px) translateY(-10px);
}

.container {
  background-color: white;
  height: 500px;
  width: 1000px;
  border-radius: 8px;
  transform: translateX(10px) translateY(-160px);
  position: relative;
}
.header {
  padding-left: 30px;
}

.button_container {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
  position: absolute;
  top: 435px;
  left: 0;
}

.table {
  &Container {
    height: 4000px;
    width: 950px;
    margin: 20px auto;
    overflow-y: auto;
    thead th {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: white;
    }
  }
  &Content {
    border-bottom: 0.5px solid rgb(162, 162, 162);
    border-collapse: collapse;
    width: 100%;

    th {
      text-align: center;
      background: #eee;
      position: relative;
      &::before {
        content: '';
        width: 100%;
        height: 3px;
        position: absolute;
        top: -1px;
        left: 0;
        background-color: white;
      }
    }
    td {
      margin: 0 auto;
      text-align: center;
    }

    td,
    th {
      height: 30px;
    }
    tr {
      border-bottom: 1px solid #ccc;
    }
  }
}
