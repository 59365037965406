.main--windows {
  flex-direction: column;
  min-width: 25%;
  height: calc(100vh - 80px);
  width: 100%;
}

.header-upper {
  display: flex;
  align-items: center;
  flex-direction: row;
  /* flex-wrap: nowrap; */
  justify-content: space-between;
  align-content: center;
  margin-top: 15px;
  width: 100%;
}

.header-upper input {
  height: 36px;
  border-radius: 8px;
  outline: none;
  border: 1px black solid;
  margin-left: 4px;
}

.upload--class {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.button-class {
  height: 36px;
  width: 80px;
  background-color: #0199fa;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  font-weight: 400;
  /* padding: 0px; */
}

.button-disable {
  background-color: rgb(189, 189, 189) !important;
  cursor: not-allowed !important;
}

.button-disable:hover {
  background-color: rgb(189, 189, 189) !important;
  box-shadow: none !important;
}

.button-class:hover {
  background-color: #018de6;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  color: black;
  height: 36px;
  width: 120px;
  background-color: #0199fa;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subfix--class {
  display: flex;
  flex-direction: row;
  justify-content: right;
  flex-wrap: wrap;
  width: 100%;
  margin-right: 36px;
}

.subfix--class div:nth-child(1) {
  margin-bottom: 5px;
}

.subfix--class input {
  padding: 10px 10px;
  border: 1px solid rgb(191, 191, 191);
  border-radius: 4px;
}

.subfix--class input:hover {
  border: 1px solid rgb(134, 134, 134);
}

.subfix--class input:focus {
  border: 1px solid #0199fa;
}

.subfix--class label {
  font-size: 15px;
  font-weight: 600;
}

.file--name {
  margin-left: 36px;
  margin-right: 36px;
  text-align: left;
  width: calc(100% - 72px);
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-items: left;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 1px;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.file--name label {
  line-height: 36px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.table-wrapper {
  width: calc(100% - 72px);
  max-height: 690px;
  /* height: 75vh; */
}

#contents-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#contents-table td,
#contents-table th {
  border: 1px solid #ddd;
  padding: 8px;
  line-height: 14px;
  text-align: left;
}

#contents-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#contents-table tr:hover {
  background-color: #ddd;
}

#contents-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #02457a;
  color: white;
  line-height: 14px;
  position: sticky;
  top: 0px;
}

#contents-table td:focus {
  outline-color: #0199fa;
  outline-width: thin;
  outline-style: solid;
  border: none;
}

.error-cell {
  outline-color: red;
  outline-width: thin;
  outline-style: solid;
  border: none;
}

.warning-cell {
  outline-color: rgb(250, 133, 0);
  outline-width: thin;
  outline-style: solid;
  border: none;
}

.other--btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: calc(100% - 72px);
  height: 10%;
  margin-right: 36px;
  margin-left: 36px;
}

.import-csv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
}

.btn-api {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
}

.icon-class {
  padding-right: 5px;
}

.main--customs {
  min-width: 660px !important;
}

@media (min-width: 1280px) and (max-width: 1365px) {
  .table-wrapper {
    max-height: 320px !important;
  }

  .main--windows {
    width: 100%;
  }
  .other--btn {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    bottom: 30px;
    grid-row-gap: 10px;
  }
  .other--btn > .import-csv {
    grid-column: 1;
    grid-row: 1 / 2;
    flex-direction: column;
  }

  .other--btn > .btn-api {
    grid-column: 2;
    grid-row: 1 / 2;
    flex-direction: column;
    align-items: flex-end;
  }
}

@media (min-width: 1366px) and (max-width: 1919px) {
  .table-wrapper {
    max-height: 446px !important;
  }

  .main--windows {
    width: 100%;
  }
  .other--btn {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    bottom: 30px;
    grid-row-gap: 10px;
  }
  .other--btn > .import-csv {
    grid-column: 1;
    grid-row: 1 / 2;
    flex-direction: column;
  }

  .other--btn > .btn-api {
    grid-column: 2;
    grid-row: 1 / 2;
    flex-direction: column;
    align-items: flex-end;
  }
}

@media (min-width: 1920) and (max-width: 2047) {
}

@media (min-width: 2047) {
}
