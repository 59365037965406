@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700&family=Roboto:wght@300;400;500;700&display=swap');

:root {
  --header-color: #02457a;
  --button-color: #0199fa;
  --button-hover: #018de6;
  --background-color: #f4f8fb;
  --black-color: #0000;
  --disable-color: #8091a4;
  --text-color: #eee;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 2rem;
  background-color: var(--header-color);
  color-scheme: var(--text-color);
  width: 100%;
}

.toolName-wrapper {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  width: 100vw;
}

nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  justify-content: flex-end;
}

header h2 {
  color: #eee;
  line-height: 60px;
  margin: 0;
  font-size: 26px;
  padding-top: 12px;
}
header p {
  color: #eee;
  line-height: 60px;
  margin-top: 10px;
  padding-top: 10px;
  padding-left: 10px;
}
nav div {
  margin: 0 20px;
  color: var(--text-color);
  text-decoration: none;
  line-height: 60px;
  font-weight: 500;
  font-size: 16px;
  color: rgb(203, 203, 203);
}
.header--ClickAble {
  cursor: pointer;
  color: #fff;
}
.header--ClickAble:hover {
  color: var(--button-color);
}

.btn--logout {
  background: var(--button-color);
  height: 36px;
  width: 96px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
}
.btn--logout:hover {
  background: var(--button-hover);
}

nav a button :hover {
  background-color: #0199fa;
}

.image-boder {
  height: 36px;
  width: 36px;
  border: 0.5px solid #a8a8a8;
  border-radius: 12%;
  margin-left: 15px;
  box-shadow: 1px 0px 3px #a7a5a587;
  background-color: #f4f8fb;
  margin-right: 5px;
}

.logo-image {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .header-toolVersion {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .header-nav {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  .header-toolName {
    display: none;
  }
}
