.wrapper {
  transform: translateX(-10px) translateY(-100px);
}

.heading {
  font-size: 22px;
  font-weight: 600;
  padding: 15px;
}

.container {
  background-color: white;
  height: 535px;
  width: 1100px;
  border-radius: 8px;
}

.title {
  padding-left: 20px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.checkbox {
  &_input {
    display: none;
    cursor: pointer;
    &:checked + .checkbox_label::after {
      visibility: visible;
      opacity: 1;
    }
    &:checked + .checkbox_label {
      color: var(--header-color);
      font-weight: 700;
    }

    &:checked + .checkbox_label2::after {
      visibility: visible;
      opacity: 1;
    }
    &:checked + .checkbox_label2 {
      color: var(--header-color);
      font-weight: 700;
    }
  }
  &_label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    padding: 3px 0;
    margin: 0;
    width: 100%;
    overflow: hidden;
    user-select: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    outline: none;
  }

  &_label2 {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    padding: 3px 0;
    margin: 0;
    width: calc(100% - 60px);
    overflow: hidden;
    user-select: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    outline: none;
  }
}

.button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 25px;
}

.tasks {
  &_container {
    display: flex;
    padding: 10px 20px 20px 20px;
    height: 330px;
  }
  &_title {
    font-size: 18px;
    font-weight: 600;
    width: 130px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  &_items {
    width: 47.5%;
    height: 100%;
  }
  &_header_icons {
    display: flex;
    justify-content: space-between;
  }
  &_move_icons {
    width: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0px;
    margin-top: 50px;
  }
  &_icon {
    height: 20px;
    width: 20px;
    cursor: pointer;
    color: var(--header-color);
    &:hover {
      color: var(--button-color);
    }
  }
  &_iconSvg {
    height: 20px;
    width: 20px;
    cursor: pointer;
    filter: invert(16%) sepia(71%) saturate(2139%) hue-rotate(189deg) brightness(97%) contrast(98%);
    &:hover {
      filter: invert(66%) sepia(64%) saturate(6407%) hue-rotate(177deg) brightness(98%) contrast(101%);
    }
  }
  &_item {
    padding-left: 15px;
    border: 1px solid gray;
    border-radius: 6px;
    height: 100%;
    overflow-y: auto;
    &Container {
      display: flex;
      justify-content: space-between;
    }
  }
  &_header {
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      transform: translateY(25px) translateX(-8px);
      width: 100%;
      height: 1px;
      background-color: gray;
    }
  }
}
