.dropdown {
  width: 100%;
  height: 44px;
  position: relative;
  user-select: none;
  &_btn {
    padding: 0px 10px;
    background-color: #fff;
    border-radius: 6px;
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 100%;
  }

  &_content {
    position: absolute;
    z-index: 100;
    top: 110%;
    left: 0;
    height: 300px;
    overflow-y: auto;
    width: 100%;
    padding: 10px;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    font-weight: 500;
    color: #333;
  }
  &_selectedItem {
    padding: 10px;
    cursor: pointer;
    width: 100%;
    transition: all 0.25s linear;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_item {
    padding: 10px;
    cursor: pointer;
    width: 100%;
    transition: all 0.25s linear;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 6px;
    }
  }

  &_name {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
  }

  &_color {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
}
