.card--wrapper {
  width: 400px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  margin: 0;
  display: flex;
  height: 85px;
  margin: 0 0 37px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.card--wrapper:hover {
  transform: translateY(-5px);
  transition-duration: 0.3s;
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
}

.card-image {
  height: 100%;
  width: 100%;
}
.card--image-boder {
  height: 60px;
  width: 60px;
  border: 0.5px solid #a8a8a8;
  border-radius: 12%;
  margin-left: 15px;
  box-shadow: 1px 0px 3px #a7a5a587;
  background-color: #f4f8fb;
}
.card--name {
  font-size: 20px;
}

.card--version {
  font-size: 16px;
}

@media (min-width: 1280px) and (max-width: 1365px) {
  .card--wrapper {
    height: 85px;
    width: 47%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-bottom: 40px;
  }
}

@media (min-width: 1366px) and (max-width: 1919px) {
  .card--wrapper {
    height: 85px;
    width: 47%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-bottom: 40px;
  }
}

@media (min-width: 1920) and (max-width: 2047) {
}

@media (min-width: 2047) {
}
