.container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
}

.heading {
  margin: 20px 0 5px 0;
}

.title {
  color: black;
  margin: 10px 0px;
  font-size: 20px;
  font-weight: 500;
}

.actions {
  width: 80px;
  height: 50px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.actions_container {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item {
  width: 100%;
  height: 26px;
  cursor: pointer;
  color: var(--header-color);
}

.item:hover {
  color: var(--button-color);
}

.menuTable {
  width: 100%;
  max-height: 608px;
  overflow-y: auto;
  border: 1px solid black;
  table {
    width: 100%;
    // height: 648px;
    overflow-y: auto;
    thead {
      position: sticky;
      top: 0; /* Don't forget this, required for the stickiness */
      box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
      z-index: 100;
      border: 1px solid black;
      border-top-style: hidden;
    }
    th {
      color: black;
      text-align: center;
      font-weight: 600;
      position: relative;
      &::before {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        top: -1px;
        left: 0;
        background-color: black;
      }
      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: -1px;
        left: 0;
        background-color: black;
      }
    }
    td,
    th {
      border: 1px solid black;
      font-size: 16px;
      padding: 10px;
    }
    tr td:first-child,
    tr th:first-child {
      border-left-style: hidden;
    }
    tr td:last-child,
    tr th:last-child {
      border-right-style: hidden;
    }

    tr:last-child td {
      border-bottom-style: hidden;
    }
  }
}

.pagination {
  // background-color: gray;
  position: absolute;
  top: 750px;
  right: 30px;
  margin-top: 20px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
}

.paginationContainer {
  width: 100%;
  height: 50px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
}
.paginationContainer ul {
  padding-right: 0px;
}

.paginationContainer a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid var(--header-color);
  cursor: pointer;
}

.paginationContainer a:last-child {
  margin-right: 0px;
}

.paginationContainer a:hover {
  color: white;
  background-color: var(--button-hover);
  border: 1px solid white;
}

.paginationDisabled a {
  color: grey;
  border: 1px solid grey;
  background-color: white;
}

.paginationDisabled a:hover {
  color: grey;
  border: 1px solid grey;
  background-color: white;
  outline: none;
}

.paginationActive a {
  color: white;
  background-color: var(--button-color);
}

.paginationActive a:active,
.paginationActive a:focus {
  outline: 0;
  -moz-outline-style: none;
}

.paginationDisabled a:active,
.paginationDisabled a:focus {
  outline: 0;
  -moz-outline-style: none;
}
