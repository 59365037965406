.calendarBottomText {
  text-anchor: middle;
  fill: #333;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  font-size: 15px;
  font-family: 'Noto Sans JP', sans-serif;
}

.calendarTopTick {
  stroke: #616161;
  height: 50px;
  width: 1px;
  display: inline-block;
}

.calendarTopText {
  text-anchor: middle;
  fill: #555;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.calendarHeader {
  fill: #ffffff;
  stroke: #616161;
  stroke-width: 0.75;
}

.gridTick {
  stroke: #616161;
  stroke-width: 0.75;
}
