.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  & span {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    padding-bottom: 3px;
    color: black;
    border-bottom: 1px solid #ababab;
  }
}
