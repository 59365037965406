.popoverGridMenu {
  width: 200px;
  // height: 105px;
  background-color: white;
  color: #333;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 6px;
  list-style: none;
  position: fixed;
  z-index: 1;
  overflow: hidden;
}

.popoverGridMenu h5 {
  margin: 0;
  padding: 10px 0px 10px 0px;
  border-bottom: 1.5px solid #333;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}

.popoverGridMenuItem {
  box-sizing: border-box;
  height: 35px;
  line-height: 1.5rem;
  font-size: 16px;
  font-weight: 400;
  border: #333;
  line-height: 35px;
  display: block;
  width: 100%;
  text-align: center;
}

.popoverGridMenuItem:hover {
  background-color: #e2f2fe;
}
