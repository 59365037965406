button {
  appearance: none;
  background: none;
  border: none;
  outline: none;
}

.container-login {
  height: 100vh;
  width: 100vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f8fb;
}

form {
  display: flex;
  position: relative;
  align-items: center;
  width: 22%;
  min-width: 410px;
  height: 100%;
}

.form {
  &-container {
    position: relative;
    display: block;
    background-color: #fff;
    padding: 30px;
    width: 100%;
    height: 450px;
    border-radius: 8px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  }
  &_noti {
    font-size: 14px;
    line-height: 14px;
    margin-top: 12px;
    text-align: center;
    width: 100%;
    color: #0199fa;
  }
  &_header {
    color: #888;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 15px;
    margin-top: 5px;
    text-align: center;
    color: #0199fa;
  }
  &_wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  &_label {
    display: block;
    color: #666;
    font-size: 14px;
    margin: 0px;
    line-height: 36px;
  }
  &_input {
    display: block;
    width: 100%;
    padding: 10px 15px;
    background-color: #f8f8f8;
    border-radius: 8px;
    font-size: 14px;
    border: none;
    outline: none;
    height: 36px;
    &:focus {
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    }
    &_eye {
      cursor: pointer;
      max-width: 20px !important;
      position: absolute;
      right: 15px;
      top: 7px;
    }
  }
  &_password-field {
    display: inline;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
  &_remember {
    display: flex;
    width: 300px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    color: #0199fa;
    margin-left: 5px;
    & input {
      height: 16px;
      width: 16px;
      border: #0199fa !important;
      background-color: #53565a;
      margin: 0px;
      margin-right: 10px;
    }
    & label {
      margin: 0px;
      line-height: 22px;
      cursor: pointer;
      user-select: none;
    }
  }
  &_button {
    display: inline-block;
    padding: 8px 10px;
    border-radius: 8px;
    background-color: #0199fa;
    margin: 5px 0 10px 0;
    height: 36px;
    color: #fff;
    cursor: pointer;
    width: 50%;
    font-size: 16px;
    border: none;
    outline: none;
    line-height: 16px;
    &:hover {
      background: var(--button-hover);
    }
  }
}

.login-submit {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  &_container {
    height: 16px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 5px;
  }
  &_content {
    padding: 0;
    text-align: left;
    color: red;
    line-height: 14px;
  }
  &_login {
    text-align: left;
    color: red;
    line-height: 14px;
  }
}

@media (min-width: 1280px) and (max-width: 1365px) {
  form {
    display: flex;
    position: relative;
    align-items: center;
    width: 26%;
    height: 100%;
  }

  .form-container {
    position: relative;
    display: block;
    background-color: #fff;
    padding: 30px;
    height: 65%;
    max-height: 455px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  }
}

@media (min-width: 1366px) and (max-width: 1919px) {
  form {
    display: flex;
    position: relative;
    align-items: center;
    width: 26%;
    height: 100%;
  }

  .form-container {
    position: relative;
    display: block;
    background-color: #fff;
    padding: 30px;
    height: 65%;
    max-height: 455px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  }
}
