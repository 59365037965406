.wrapper {
  transform: translateX(-10px) translateY(-10px);
}

.heading {
  font-size: 24px;
  font-weight: 500;
  padding: 15px;
}

.container {
  background-color: white;
  height: 465px;
  width: 430px;
  border-radius: 8px;
}

.desc {
  padding-left: 20px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.list_columns {
  height: 300px;
  width: 390px;
  border: 1px solid var(--header-color);
  border-radius: 8px;
  margin-left: 20px;
  padding: 10px 15px 15px 15px;
}

.detail {
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
}
.column_item {
  padding: 10px 0px;
  line-height: 1;
}

.button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
}

.checkbox {
  &_input {
    display: none;
    &:checked + .checkbox_label::after {
      visibility: visible;
      opacity: 1;
    }
    &:checked + .checkbox_label {
      color: var(--header-color);
      font-weight: 500;
    }
  }
  &_label {
    position: relative;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    &::after {
      content: '';
      height: 10px;
      width: 16px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-100%) translateX(310px) rotate(-45deg);
      border-left: 3px solid var(--header-color);
      border-bottom: 3px solid var(--header-color);
      visibility: hidden;
      opacity: 0;
      transform: 0.25s linear;
    }
  }
}
