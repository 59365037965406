.row {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  gap: 10px;
  position: relative;
  margin-left: 19px;
  &_expandIcon {
    margin-right: 10px;
    cursor: pointer;
    height: 18px;
    width: 18px;
    transition: all 0.15s linear;
    &_open {
      transform: rotate(90deg);
    }
  }
  &_saveIcon {
    position: absolute;
    right: 90px;
    top: 2px;
  }
  &_action {
    width: 80px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 25px;
    margin-right: 18px;
  }
  &_item {
    height: 20px;
    display: inline-block;
    fill: var(--header-color);
    padding: 0;
    margin: 0;
  }
  &_saveIcon {
    height: 20px;
    display: inline-block;
    color: var(--header-color);
  }
  &_item:hover {
    cursor: pointer;
    fill: var(--button-color);
  }
  &_saveIcon:hover {
    cursor: pointer;
    color: var(--button-color);
  }
}
