.loginDialog-container {
  background-color: white;
  width: 500px;
  height: 160px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.button--wrapper {
  height: 100%;
  width: 100%;
  padding-right: 16px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 16px;
}

.button-close {
  padding: 8px 20px;
  min-width: 100px;
  font-size: 16px;
  border-radius: 6px;
  background: white;
  color: #0199fa;
  border: 1px solid #0199fa;
}

.button-close:hover {
  background: #f5f5f5;
  font-weight: 500;
  border: 2px solid #0199fa;
}

.button-ok {
  padding: 8px 20px;
  min-width: 100px;
  font-size: 16px;
  border-radius: 6px;
  background: #0199fa;
  color: white;
}

.button-OK:hover {
  background: #018de6;
}



.overlay {
  background-color: rgba(85, 85, 85, 0.5);
  position: fixed;
  width: 100vw;
  height: calc(100vh + 105px);
  padding: 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
