.taskListWrapper {
  /* display: table; */
  border-bottom: #e6e4e4 1px solid;
  border-left: #e6e4e4 1px solid;
}

.taskListTableRow {
  display: table-row;
  /* text-overflow: ellipsis; */
}

.SelectedRow {
  background-color: #d4edff;
}

.SelectedRow input {
  background-color: #d4edff;
}

.taskListTable_icon {
  width: 13px;
  height: 13px;
  padding: 3px 10px 0 5px;
  color: var(--header-color);
}
/* .taskListTableRow:nth-of-type(even) {
  background-color: #f5f5f5;
} */

.taskListCell {
  display: table-cell;
  vertical-align: middle;
  border-bottom: 1px solid rgb(196, 196, 196);
}

.taskListCell2 {
  display: table-cell;
  vertical-align: middle;
  margin-right: 21px;
  border-bottom: 1px solid rgb(196, 196, 196);
  position: relative;
}
.taskListCell2:not(:first-child)::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 30px;
  background: rgb(196, 196, 196);
  transform: translateY(-50%);
}
.taskListNameWrapper {
  display: flex;
  align-items: center;
}

.taskListExpander {
  color: rgb(86 86 86);
  font-size: 0.6rem;
  padding: 0.15rem 0.2rem 0rem 0.2rem;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
}

.taskListEmptyExpander {
  font-size: 0.6rem;
  padding-left: 1rem;
  user-select: none;
  -webkit-user-select: none;
}

.taskListName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: none;
  border-radius: 3px;
  padding-left: 3px;
}

.taskListName:focus {
  background-color: #e0effa;
  outline: 2px solid white;
}
