.gridRow {
  fill: #fff;
}

/* .gridRow:nth-child(even) {
  fill: #f5f5f5;
} */

.gridRowLine {
  stroke: black;
  stroke-width: 0.15;
}

.gridTick {
  stroke: #616161;
  stroke-width: 0.2;
}

.gridTickMonth {
  stroke: #616161;
  stroke-width: 0.75;
}
