.popoverHeaderMenu {
  width: 200px;
  height: 105px;
  background-color: white;
  color: #333;
  border-radius: 6px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  list-style: none;
  position: fixed;
  z-index: 1000000;
  overflow: hidden;
  font-weight: 500;
}

.popoverHeaderItem {
  box-sizing: border-box;
  height: 35px;
  line-height: 1.5rem;
  font-size: 16px;
  font-weight: 400;
  border: #333;
  margin: auto;
  line-height: 16px;
  cursor: pointer;
  padding: 10px 10px 5px 10px;
}
.popoverHeaderItem:hover {
  background-color: #e6f7ff;
}
