
#viewer {
    width: 100%;
    height: calc(100vh - 60px);
    position: sticky;
    border: 1px solid lightgray;
    min-height: 50vh;
    object-fit: cover;
    background-size:     cover;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: center center; 
    min-width: 40vw;
}

.view--container{
    width: 100vw;
}