.wrapper {
  transform: translateX(-10px) translateY(-10px);
}

.container {
  background-color: white;
  height: 495px;
  width: 400px;
  border-radius: 8px;
  transform: translateX(10px) translateY(-160px);
  position: relative;
  padding: 20px;
}
.header {
  margin-bottom: 22px;
  font-size: 20px;
  font-weight: 500;
}

.title {
  font-size: 16px;
  font-weight: normal;
}

.table {
  &Container {
    width: 100%;
    height: 340px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 10px 10px 0px;
    margin-top: 10px;
    border-radius: 8px;
    border: 1px solid gray;
  }
}

.checkbox {
  &_input {
    display: none;
    &:checked + .checkbox_label::after {
      visibility: visible;
      opacity: 1;
    }
    &:checked + .checkbox_label {
      color: var(--header-color);
      font-weight: 500;
    }
  }
  &_label {
    position: relative;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    margin: 0px 10px;
    padding: 5px 30px 5px;

    &:hover {
      width: 335px;
      border-radius: 6px;
      background-color: #c9eeff;
      // color: white;
      font-weight: 500;
    }
    &::after {
      content: '';
      height: 10px;
      width: 16px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-80%) translateX(3px) rotate(-45deg);
      border-left: 3px solid var(--header-color);
      border-bottom: 3px solid var(--header-color);
      visibility: hidden;
      opacity: 0;
      transform: 0.25s linear;
      cursor: pointer;
    }
  }
}

.button_container {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  position: absolute;
  top: 435px;
  left: 0;
}
