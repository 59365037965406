.main-container {
  display: flex;
  min-width: 1000px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px);
  overflow: hidden;
}

.class--image {
  height: 60px;
  width: 60px;
  align-items: center;
}

.content--container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-swapper {
  height: 90px;
  padding-left: 10px;
}

.text-swapper h3 {
  margin-top: 14px;
}
