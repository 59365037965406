.wrapper {
  transform: translateX(-10px) translateY(-10px);
}

.heading {
  font-size: 24px;
  font-weight: 500;
  padding: 15px 15px 0px 15px;
  user-select: none;
}

.container {
  background-color: white;
  height: 538px;
  width: 400px;
  border-radius: 8px;
  transform: translateX(0px) translateY(-100px);
}

.button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
}

.tab {
  &Container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 0px 10px;
    height: 423px;
    min-width: 100% !important;
  }
  &ListItems {
    width: 100%;
  }
  &Item {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s linear all;
    width: 120px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px transparent solid;
    &_active {
      border-bottom: 2px var(--header-color) solid;
      font-weight: 600;
      color: var(--header-color);
    }
    &:hover {
      background-color: #ddd;
    }
    &:active {
      background-color: #ccc;
    }
  }

  &Contents {
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-radius: 0px 0px 6px 6px;
    height: 400px;
    width: 100%;
    // animation: fadeEffect 1s;
  }
  &LabelInput {
    font-size: 16px;
    display: inline-block;
    font-weight: 400;
  }

  &InputText {
    display: inline-block;
    width: 100px;
    border-radius: 3px;
    border: 1px solid var(--header-color);
    outline: none;
    font-size: 16px;
    padding-left: 5px;
    height: 30px;
  }

  &Checkbox {
    visibility: collapse;
    display: block;
    position: relative;
    margin: 10px 0px !important;
    padding-right: 10px;
    height: 15px;
    width: 15px;
    cursor: pointer;
    &::after {
      content: 'Separate group task by blank row';
      height: 26px;
      width: 250px;
      font-size: 16px;
      display: inline-block;
      font-weight: 400;
      position: absolute;
      left: 25px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &Task {
    display: none;
    &_active {
      display: block;
    }
    &Wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
    }
    &Table {
      &Wrapper {
        height: 160px;
        overflow-y: auto;
        thead th {
          position: sticky;
          top: 0;
          z-index: 1;
          background-color: #ccc;
        }
      }
      &Content {
        border: 0.5px solid rgb(162, 162, 162);
        border-collapse: collapse;
        width: 100%;

        th {
          text-align: center;
          background: #eee;
          position: relative;
          &::before {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            top: -1px;
            left: 0;
            background-color: rgb(162, 162, 162);
          }
        }
        td {
          padding-left: 10px;
        }

        td,
        th {
          border: 1px solid rgb(162, 162, 162);
          height: 30px;
        }
      }
    }
  }

  &Background {
    display: none;
    &_active {
      display: block;
    }
    &Table {
      &Wrapper {
        height: 150px;
        overflow-y: auto;
        thead th {
          position: sticky;
          top: 0;
          z-index: 1;
          background-color: #ccc;
        }
      }
      &Content {
        border: 0.5px solid rgb(162, 162, 162);
        border-collapse: collapse;
        width: 100%;
        th {
          text-align: center;
          background: #eee;
          position: relative;
          height: 30px;
          font-weight: 500;
          &::before {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            top: -1px;
            left: 0;
            background-color: rgb(162, 162, 162);
          }
        }
        td {
          padding-left: 10px;
        }
        td,
        th {
          border: 1px solid rgb(162, 162, 162);
          height: 30px;
        }
      }
    }
  }
  &Month {
    display: none;
    &_active {
      display: block;
    }
  }
}

@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
