.dialog {
  &_container {
    background-color: white;
    width: 500px;
    height: 160px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &_header {
    text-align: center;
    width: 100%;
    margin-top: 20px;
  }
}
