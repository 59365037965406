.progress {
  width: 400px;
  height: 10px;
  background: linear-gradient(to right, #15a5ff, #026eb0);
  background-color: #ccc;
  border-radius: 4px;
  background-size: 20%;
  background-repeat: repeat-y;
  background-position: -25% 0;
  animation: scroll 1.2s ease-in-out infinite;
}

@keyframes scroll {
  50% {
    background-size: 80%;
  }
  100% {
    background-position: 125% 0;
  }
}

.progress-container {
  background-color: white;
  width: 500px;
  height: 150px;
  padding: 10px;
  border-radius: 5px;
}
.progress-content {
  margin-top: -10px;
  height: 150px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.progress-button {
  padding: 8px 20px;
  min-width: 100px;
  font-size: 16px;
  background: #0199fa;
  border-radius: 6px;
  color: white;
}

.progress-button:hover {
  background: #018de6;
}

.progress-title {
  text-align: center;
  margin-top: 15px;
  width: 100%;
  font-size: 24px;
  font-weight: 500;
}

.overlay {
  background-color: rgba(85, 85, 85, 0.5);
  position: fixed;
  top: 0px;
  width: 100vw;
  height: calc(100vh + 105px);
  padding: 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
