.ganttVerticalContainer {
  overflow: hidden;
  font-size: 0;
  margin: 0;
  padding: 0;

  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.horizontalContainer {
  margin: 0;
  padding: 0;
  overflow: hidden;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
}

.wrapper {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  outline: none;
  position: relative;

  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey;  */
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border: 4px solid transparent;
    border-radius: 12px;
    background-clip: content-box;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    border: 4px solid transparent;
    border-radius: 12px;
    background-clip: content-box;
  }
}

