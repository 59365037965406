.container {
  background-color: white;
  height: auto;
  width: 400px;
  border-radius: 8px;
}

.heading {
  font-size: 24px;
  font-weight: 500;
  padding: 15px;
}

.contents {
  padding: 5px 20px;
}

.item {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  &_label {
    font-size: 16px;
    font-weight: normal;
  }
  &_input {
    outline: none;
    font-size: 16px;
    padding: 5px 8px;
    border-radius: 4px;
    border: 1px solid #7e7d7d;
    &:focus {
      border: 1px var(--button-color) solid;
    }
  }
}
.button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-bottom: 21px;
}
