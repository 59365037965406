.wrapper {
  transform: translateX(-10px) translateY(-100px);
}

.heading {
  font-size: 24px;
  font-weight: 500;
  padding: 15px;
}

.container {
  background-color: white;
  height: 510px;
  width: 400px;
  border-radius: 8px;
}

.title {
  padding-left: 20px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
}
.views {
  &_container {
    display: flex;
    padding: 10px 10px 20px 10px;
    height: 330px;
    border: 1px solid gray;
    border-radius: 6px;
    width: 350px;
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &_item {
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    &:hover {
      background-color: rgb(223, 238, 247);
      border-radius: 6px;
    }
    &:hover {
      .icons_item {
        display: block;
      }
    }
    .icons {
      &_container {
        width: 66px;
        display: flex;
        gap: 8px;
        align-items: center;
      }
      &_item {
        display: none;
        color: var(--header-color);
        &:hover {
          color: var(--button-color);
        }
      }
    }
  }
}
