.switch-swapper{
  display: flex;
  height: 28px;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}
.switch-swapper p{
    text-align: center;
    line-height: 28px;
    padding-left: 8px;
    font-size: 15px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 22px;
    margin: 0;
  }

  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
    height: 22px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  p {
    margin: 0;
  }