#viewer {
  width: 100%;
  height: calc(100vh - 60px);
  position: sticky;
  border: 1px solid lightgray;
  min-height: 50vh;
  object-fit: cover;
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  min-width: 500px;
}

.view--container {
  width: 100vw;
}

.SplitterHeight {
  height: calc(100vh - 60px) !important;
  overflow: hidden !important;
  display: flex;
}

.customSplitter {
  border-right: 1px solid #02457a !important;
  border-left: 1px solid #02457a !important;
  width: 3px !important;
  background-color: #02457a !important;
}

.customSplitter:hover {
  width: 4px !important;
  background-color: #02457a !important;
}
