.taskListScroll {
  overflow-x: hidden;
  /* min-width: 730px;
  max-width: 730px; */
  min-width: 100%;
  max-width: 100%;
  background-color: white;
  position: relative;
}

/* width */
.taskListScroll::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

/* Track */
.taskListScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
  background-color: transparent;
}

/* Handle */
.taskListScroll::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  background: rgba(0, 0, 0, 0.2);
  background: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2));
  border-radius: 10px;
  background-clip: padding-box;
}

/* Handle on hover */
.taskListScroll::-webkit-scrollbar-thumb:hover {
  border: 4px solid transparent;
  background: rgba(0, 0, 0, 0.3);
  background: var(--palette-black-alpha-30, rgba(0, 0, 0, 0.3));
  background-clip: padding-box;
}
.taskListScroll::-webkit-scrollbar-corner {
  background: transparent;
}
