.popoverTaskMenu {
  width: 200px;
  height: fit-content;
  background-color: white;
  color: #333;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 6px;
  list-style: none;
  position: fixed;
  z-index: 100000;
  overflow: hidden;
}

.popoverTaskItem {
  box-sizing: border-box;
  height: 35px;
  line-height: 1.5rem;
  font-size: 16px;
  font-weight: 400;
  border: #333;
  margin: auto;
  line-height: 16px;
  cursor: pointer;
  padding: 10px 10px 5px 10px;
}

.popoverTaskItem:hover {
  background-color: #e6f7ff;
}

.popoverTaskItem_disable {
  color: #b0b0b0;
  pointer-events: none;
}
.popoverTaskItem_disable:hover {
  background-color: #e9e9e9;
}
